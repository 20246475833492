<template>
  <div class="staging">

    <HelloWorld :msg="$t('welcome', ['Wakaru.Org'])"/>

  </div>
</template>

<script>
import HelloWorld from '../components/HelloWorld.vue';

export default {
  name: 'Staging',
  components: {
    HelloWorld,
  },

};

</script>

<style lang="stylus">

</style>
